// Module Imports
import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { pattern1, pattern2, pattern3, pattern4 } from "../images/patterns"

// Custom Imports
import { Container, Headline } from "../elements"
import { spacing, breakpoints, headlineSizes } from "../designstystem"

// Component
const Service = ({ counter = 0, title, text, image, decoratorPattern }) => {
  let decoratorSVG
  switch (decoratorPattern) {
    case 1:
      decoratorSVG = pattern1
      break
    case 2:
      decoratorSVG = pattern2
      break
    case 3:
      decoratorSVG = pattern3
      break
    case 4:
      decoratorSVG = pattern4
      break
  }

  return (
    <StyledService isOdd={counter % 2}>
      <Container.flex>
        <div className="column">
          <Img
            className="fluid"
            fluid={image.childImageSharp.fluid}
            alt={title}
          />
          {decoratorSVG && (
            <img className="decorator" src={decoratorSVG} alt={title} />
          )}
        </div>
        <div className="column">
          <article>
            <Headline
              marginBottom={spacing[2]}
              size={headlineSizes.small}
              counter={counter}
            >
              {title}
            </Headline>

            <p>{text}</p>
          </article>
        </div>
      </Container.flex>
    </StyledService>
  )
}

// Styling
const StyledService = styled.div`
  .column {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .fluid {
      width: 100%;
    }
  }
  .decorator {
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 100%;
    top: 0;
    ${({ isOdd }) => (isOdd ? "left: 0" : "right: 0")}
  }

  article {
    margin-top: ${spacing[3]};
    text-align: center;
  }

  p {
    margin-top: 0;
    max-width: 30em;
  }

  &:not(:last-child) {
    article {
      padding-bottom: ${spacing[6]};
    }
  }

  ${Container.flex} {
    justify-content: center;
  }

  @media screen and (min-width: ${breakpoints.md}) {
    .column {
      max-width: none;
      width: 50%;

      &:first-child {
        order: ${props => (props.isOdd ? 1 : 0)};
      }
    }

    .decorator {
      width: 120%;
      ${({ isOdd }) => (isOdd ? "left: -20%" : "right: -20%")}
    }

    ${Container.flex} {
      justify-content: space-between;
    }

    article {
      ${({ isOdd }) =>
        isOdd ? `padding-right: ${spacing[3]}` : `padding-left: ${spacing[3]}`};
    }

    &:not(:last-child) {
      article {
        margin-top: 0;
        padding-bottom: 0;
      }
    }
  }
`

export default Service
