// React Imports
import React from "react"
import styled from "styled-components"
import ResponsiveEmbed from "react-responsive-embed"

// Custom Imports
import { PageModule, Headline } from "../elements"

// Component
const Map = ({ counter = 4 }) => (
  <StyledMap>
    <Headline counter={counter}>So finden Sie uns</Headline>
    <ResponsiveEmbed
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2594.3093478784217!2d6.653961316438405!3d49.44086997934882!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47950b950b421741%3A0x82ee6a2b456d7e13!2sMerchinger%20Str.%2069%2C%2066663%20Merzig!5e0!3m2!1sde!2sde!4v1571729117194!5m2!1sde!2sde"
      ratio="6:2"
    />
  </StyledMap>
)

// Styling
const StyledMap = styled(PageModule)`
  padding-bottom: 0;
`

export default Map
