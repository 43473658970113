// Module Imports
import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Container, PageModule, Headline } from "../elements"
import { spacing } from "../designstystem"
import Service from "./service"

//
const ServiceList = () => {
  const data = useStaticQuery(serviceListStaticQuery)
  const services = data.allServicesJson.nodes
  return (
    <PageModule as="main">
      <Container>
        <Headline marginBottom={spacing[4]}>
          <span className="font-foundry-form">Was wir machen &amp;</span>
          <br />
          Was wir wollen
        </Headline>
      </Container>
      {services.map((service, counter) => (
        <Service key={counter} counter={counter + 1} {...service} />
      ))}
    </PageModule>
  )
}

export const serviceListStaticQuery = graphql`
  {
    allServicesJson {
      nodes {
        title
        text
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        decoratorPattern
      }
    }
  }
`
export default ServiceList
