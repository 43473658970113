import React from "react"
import Helmet from "react-helmet"

import Layout from "../components/layout"
import ServiceList from "../components/service-list"
// import Contact from "../components/contact"
import Map from "../components/map"

const IndexPage = () => (
  <Layout isFrontPage={true}>
    <Helmet>
      <meta charSet="utf-8" />
      <title>la rabasse Invest GmbH - Startseite</title>
    </Helmet>
    <ServiceList />
    {/* <Contact /> */}
    <Map />
  </Layout>
)

export default IndexPage
